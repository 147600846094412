<template>
  <div class="richmond">
    <van-overlay :show="show" @click="show = false">
      <div class="wrapper">
        <div class="close" @click.stop="getCoupon">
          <img src="../../../assets/richmond/Union.png" alt="" />
        </div>
        <img
          v-if="couponAC == 1"
          class="couponbtn"
          src="../../../assets/richmond/Group26.png"
          alt=""
        />
        <img
          v-else
          class="couponbtn"
          src="../../../assets/richmond/Group27.png"
          alt=""
        />
        <img
          class="nicebtn"
          @click="getCoupon"
          src="../../../assets/richmond/Clicktoclaim.png"
          alt=""
        />
      </div>
    </van-overlay>
    <m-header-richmond title="Night Market Coupon"></m-header-richmond>
    
    <div class="main">
      <div class="bg"></div>
      <div class="point"></div>
      <!-- 广告 -->
      <div class="main-head">
        <!-- <div class="advertise">
          <div>广告位</div>
          <div>广告位</div>
        </div> -->
        <div class="pic-add-cut" v-if="false">
            <div class="pic" @click="add" v-if="btnShow"><img src="@/assets/richmond/add.png" alt=""></div>
            <div class="pic" @click="cut" v-else><img src="@/assets/richmond/cut.png" alt=""></div>
        </div>
        <div class="head-img">
          <div class="head_bg">
            <img
              :src="shopDetail.bus_pic ? shopDetail.bus_pic.url : ''"
              alt=""
            />
          </div>
          <div class="head_content">
            <p>{{ shopDetail.bus_name }}</p>
            <div style="display: flex">
              <img src="../../../assets/richmond/map.png" alt="" />
              <div class="addressMap">{{ arr.join("/") }}</div>
            </div>
          </div>
        </div>
        <div class="head-map-box" v-if="false">
          <!-- <img
          class="head-map"
          src="../../../assets/richmond/MaskGroup.png"
          alt=""
        /> -->
          <div class="max-b" ref="maxB">
            <div ref="map" class="mapSet">
              <div class="cOne">
                <el-checkbox-group v-model="checkarr.checkboxC1">
                  <el-checkbox-button
                    v-for="(city, index) in cities.citiesC1"
                    :label="city.area"
                    :key="index"
                    :style="{background: city.id ? 'red' : ''}"
                    :disabled="true"
                    >{{ index + 1 }}</el-checkbox-button
                  >
                </el-checkbox-group>
              </div>
              <div class="cTwo">
                <el-checkbox-group v-model="checkarr.checkboxC2">
                  <el-checkbox-button
                    v-for="(city, index) in cities.citiesC2"
                    :label="city.area"
                    :key="index"
                    :style="{background: city.id ? 'red' : ''}"
                    :disabled="true"
                    >{{ index + 11 }}</el-checkbox-button
                  >
                </el-checkbox-group>
              </div>
              <div class="bOne">
                <el-checkbox-group v-model="checkarr.checkboxB1">
                  <el-checkbox-button
                    v-for="(city, index) in cities.citiesB1"
                    :label="city.area"
                    :key="index"
                    :style="{background: city.id ? 'red' : ''}"
                    :disabled="true"
                    >{{ index + 1 }}</el-checkbox-button
                  >
                </el-checkbox-group>
              </div>
              <div class="bTwo">
                <el-checkbox-group v-model="checkarr.checkboxB2">
                  <el-checkbox-button
                    v-for="(city, index) in cities.citiesB2"
                    :label="city.area"
                    :key="index"
                    :style="{background: city.id ? 'red' : ''}"
                    :disabled="true"
                    >{{ index + 22 }}</el-checkbox-button
                  >
                </el-checkbox-group>
              </div>
              <div class="aOne">
                <el-checkbox-group v-model="checkarr.checkboxA1">
                  <el-checkbox-button
                    v-for="(city, index) in cities.citiesA1"
                    :label="city.area"
                    :key="index"
                    :style="{background: city.id ? 'red' : ''}"
                    :disabled="true"
                    >{{ index + 1 }}</el-checkbox-button
                  >
                </el-checkbox-group>
              </div>    
              <div class="fOne">
                <el-checkbox-group v-model="checkarr.checkboxS1">
                  <el-checkbox-button
                    v-for="(city, index) in cities.citiesS1"
                    :label="city.area"
                    :key="index"
                    :style="{background: city.id ? 'red' : ''}"
                    :disabled="true"
                    >{{city.area}}</el-checkbox-button
                  >
                </el-checkbox-group>
              </div>
              <div class="fTwo">
                <el-checkbox-group v-model="checkarr.checkboxF5">
                  <el-checkbox-button
                    v-for="(city, index) in cities.citiesF5"
                    :label="city.area"
                    :key="index"
                    :style="{background: city.id ? 'red' : ''}"
                    :disabled="true"
                    >F{{ index + 104 }}</el-checkbox-button
                  >
                </el-checkbox-group>
              </div>
              <div class="fThree">
                <el-checkbox-group v-model="checkarr.checkboxF1">
                  <el-checkbox-button
                    v-for="(city, index) in cities.citiesF1"
                    :label="city.area"
                    :key="index"
                    :style="{background: city.id ? 'red' : ''}"
                    :disabled="true"
                    >F{{ index + 75 }}</el-checkbox-button
                  >
                </el-checkbox-group>
              </div>
              <div class="fFour">
                <el-checkbox-group v-model="checkarr.checkboxF2">
                  <el-checkbox-button
                    v-for="(city, index) in cities.citiesF2"
                    :label="city.area"
                    :key="index"
                    :style="{background: city.id ? 'red' : ''}"
                    :disabled="true"
                    >F{{ index + 46 }}</el-checkbox-button
                  >
                </el-checkbox-group>
              </div>
              <div class="fFive">
                <el-checkbox-group v-model="checkarr.checkboxF3">
                  <el-checkbox-button
                    v-for="(city, index) in cities.citiesF3"
                    :label="city.area"
                    :key="index"
                    :style="{background: city.id ? 'red' : ''}"
                    :disabled="true"
                    >F{{ index + 23 }}</el-checkbox-button
                  >
                </el-checkbox-group>
              </div>
              <div class="mOne">
                <el-checkbox-group v-model="checkarr.checkboxF4">
                  <el-checkbox-button
                    v-for="(city, index) in cities.citiesF4"
                    :label="city.area"
                    :key="index"
                    :style="{background: city.id ? 'red' : ''}"
                    :disabled="true"
                    >F{{ index }}</el-checkbox-button
                  >
                </el-checkbox-group>
              </div>
              <div class="sOne">
                <el-checkbox-group v-model="checkarr.checkboxM1">
                  <el-checkbox-button
                    v-for="(city, index) in cities.citiesM1"
                    :label="city.area"
                    :key="index"
                    :style="{background: city.id ? 'red' : ''}"
                    :disabled="true"
                    >{{city.area.slice(5) }}</el-checkbox-button
                  >
                </el-checkbox-group>
              </div>
              <div class="spot" ref="spot">
                <div class="ripple" ref="ripple"></div>
                <img src="@/assets/richmond/map3.png" alt="">
              </div>
            </div>
          </div>
          <!-- <div class="market" ref="market"></div> -->
        </div>
      </div>
      <div class="coupon">
        <!-- <div class="coupon_title">
          <li>Only 1 coupon can be used per</li>
          <p>person time</p>
        </div> -->
        <div class="box">
          <li class="item">
            <div class="item-pic">
              <img
                :class="$route.query.state == 1 ? 'item-pic-grey' : ''"
                :src="shopDetail.pic_link ? shopDetail.pic_link.url : ''"
                alt=""
              />
              <div v-if="$route.query.state == 1">
                USED
              </div>
            </div>
            <!-- <div class="item-content">
              <div class="item-market">
                <div><img src="../../../assets/richmond/map.png" alt="" /></div>
                <div>D7</div>
              </div>
              <div class="use-btn">USE NEW</div>
            </div> -->
          </li>
        </div>
        <div class="rules">
          <div class="rule_title">
            Rules of Use
            <div class="rule_titlebg"></div>
          </div>
          <li>Limit one coupon per customer.</li>
          <div class="rule_content">
            Only valid during the Richmond Night Market 2022 event season (Apr 29-Sep 10, 2022). Coupon must be presented and surrendered at the time of purchase. No substitutions allowed by user. Not valid with other offers, promotions, coupons or rebates. Subject to product availability and while supplies last. No cash value  and credit back. Void if copied, altered, transferred, sold, or prohibited by law. Exclusions and/or purchase limitations may apply. We reserve the right to cancel any coupon due to unauthorized or ineligible use of discounts, or to modify or cancel this promotion due to unforeseen circumstances. 
          </div>
        </div>
      </div>
    </div>
    <div class="flex-btn" v-if="$route.query.state == 1">
      <div class="flex-item use-btn2">
        <div
          class="use-btn2"
          @click="useNow(shopDetail.id, shopDetail.pic_link.url)"
        >
          USED
        </div>
      </div>
    </div>
        <div class="flex-btn" v-else>
      <div class="flex-item">
        <div
          @click="useNow(shopDetail.id, shopDetail.pic_link.url)"
        >
          Use This Coupon
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { coupon_detail, use_coupon } from "@/api/en/night_market.js";
import { exchangeCoupon } from "@/api/en/coupon.js";
import MHeaderRichmond from "@/components/en/m-header-richmond.vue";
export default {
  name: "Index",
  components: {
    MHeaderRichmond,
  },
  data() {
    return {
      abc: false,
      checkarr: {
        checkboxC1: [],
        checkboxC2: [],
        checkboxB1: [],
        checkboxB2: [],
        checkboxA1: [],
        checkboxS1: [],
        checkboxF1: [],
        checkboxF2: [],
        checkboxF3: [],
        checkboxF4: [],
        checkboxF5: [],
        checkboxM1: [],
      },
      cities: {
        citiesC1: [
          {
            area: "C1",
          },
          {
            area: "C2",
          },
          {
            area: "C3",
          },
          {
            area: "C4",
          },
          {
            area: "C5",
          },
          {
            area: "C6",
          },
          {
            area: "C7",
          },
          {
            area: "C8",
          },
          {
            area: "C9",
          },
          {
            area: "C10",
          },
        ],
        citiesC2: [
          {
            area: "C11",
          },
          {
            area: "C12",
          },
          {
            area: "C13",
          },
          {
            area: "C14",
          },
          {
            area: "C15",
          },
          {
            area: "C16",
          },
          {
            area: "C17",
          },
          {
            area: "C18",
          },
          {
            area: "C19",
          },
          {
            area: "C20",
          },
        ],
        citiesB1: [
          {
            area: "B1",
          },
          {
            area: "B2",
          },
          {
            area: "B3",
          },
          {
            area: "B4",
          },
          {
            area: "B5",
          },
          {
            area: "B6",
          },
          {
            area: "B7",
          },
          {
            area: "B8",
          },
          {
            area: "B9",
          },
          {
            area: "B10",
          },
          {
            area: "B11",
          },
          {
            area: "B12",
          },
          {
            area: "B13",
          },
          {
            area: "B14",
          },
          {
            area: "B15",
          },
          {
            area: "B16",
          },
          {
            area: "B17",
          },
          {
            area: "B18",
          },
          {
            area: "B19",
          },
          {
            area: "B20",
          },
          {
            area: "B21",
          },
        ],
        citiesB2: [
          {
            area: "B22",
          },
          {
            area: "B23",
          },
          {
            area: "B24",
          },
          {
            area: "B25",
          },
          {
            area: "B26",
          },
          {
            area: "B27",
          },
          {
            area: "B28",
          },
          {
            area: "B29",
          },
          {
            area: "B30",
          },
          {
            area: "B31",
          },
          {
            area: "B32",
          },
          {
            area: "B33",
          },
          {
            area: "B34",
          },
          {
            area: "B35",
          },
          {
            area: "B36",
          },
          {
            area: "B37",
          },
          {
            area: "B38",
          },
          {
            area: "B39",
          },
          {
            area: "B40",
          },
          {
            area: "B41",
          },
          {
            area: "B42",
          },
        ],
        citiesA1: [
          {
            area: "A1",
          },
          {
            area: "A2",
          },
          {
            area: "A3",
          },
          {
            area: "A4",
          },
          {
            area: "A5",
          },
          {
            area: "A6",
          },
          {
            area: "A7",
          },
          {
            area: "A8",
          },
          {
            area: "A9",
          },
          {
            area: "A10",
          },
          {
            area: "A11",
          },
          {
            area: "A12",
          },
          {
            area: "A13",
          },
          {
            area: "A14",
          },
          {
            area: "A15",
          },
          {
            area: "A16",
          },
          {
            area: "A17",
          },
          {
            area: "A18",
          },
          {
            area: "A19",
          },
          {
            area: "A20",
          },
          {
            area: "A21",
          },
          {
            area: "A22",
          },
          {
            area: "A23",
          },
        ],
        citiesS1: [
          {
            area: "S2",
          },
          {
            area: "S1A/S1B",
          },
        ],
        citiesF5: [
          {
            area: "F104",
          },
          {
            area: "F105",
          },
          {
            area: "F106",
          },
          {
            area: "F107",
          },
        ],
        citiesF1: [
          {
            area: "F75",
          },
          {
            area: "F76",
          },
          {
            area: "F77",
          },
          {
            area: "F78",
          },
          {
            area: "F79",
          },
          {
            area: "F80",
          },
          {
            area: "F81",
          },
          {
            area: "F82",
          },
          {
            area: "F83",
          },
          {
            area: "F84",
          },
          {
            area: "F85",
          },
          {
            area: "F86",
          },
          {
            area: "F87",
          },
          {
            area: "F88",
          },
          {
            area: "F89",
          },
          {
            area: "F90",
          },
          {
            area: "F91",
          },
          {
            area: "F92",
          },
          {
            area: "F93",
          },
          {
            area: "F94",
          },
          {
            area: "F95",
          },
          {
            area: "F96",
          },
          {
            area: "F97",
          },
          {
            area: "F98",
          },
          {
            area: "F99",
          },
          {
            area: "F100",
          },
          {
            area: "F101",
          },
          {
            area: "F102",
          },
          {
            area: "F103",
          },
        ],
        citiesF2: [
          {
            area: "F46",
          },
          {
            area: "F47",
          },
          {
            area: "F48",
          },
          {
            area: "F49",
          },
          {
            area: "F50",
          },
          {
            area: "F51",
          },
          {
            area: "F52",
          },
          {
            area: "F53",
          },
          {
            area: "F54",
          },
          {
            area: "F55",
          },
          {
            area: "F56",
          },
          {
            area: "F57",
          },
          {
            area: "F58",
          },
          {
            area: "F59",
          },
          {
            area: "F60",
          },
          {
            area: "F61",
          },
          {
            area: "F62",
          },
          {
            area: "F63",
          },
          {
            area: "F64",
          },
          {
            area: "F65",
          },
          {
            area: "F66",
          },
          {
            area: "F67",
          },
          {
            area: "F68",
          },
          {
            area: "F69",
          },
          {
            area: "F70",
          },
          {
            area: "F71",
          },
          {
            area: "F72",
          },
          {
            area: "F73",
          },
          {
            area: "F74",
          },
        ],
        citiesF3: [
          {
            area: "F23",
          },
          {
            area: "F24",
          },
          {
            area: "F25",
          },
          {
            area: "F26",
          },
          {
            area: "F27",
          },
          {
            area: "F28",
          },
          {
            area: "F29",
          },
          {
            area: "F30",
          },
          {
            area: "F31",
          },
          {
            area: "F32",
          },
          {
            area: "F33",
          },
          {
            area: "F34",
          },
          {
            area: "F35",
          },
          {
            area: "F36",
          },
          {
            area: "F37",
          },
          {
            area: "F38",
          },
          {
            area: "F39",
          },
          {
            area: "F40",
          },
          {
            area: "F41",
          },
          {
            area: "F42",
          },
          {
            area: "F43",
          },
          {
            area: "F44",
          },
          {
            area: "F45",
          }
        ],
        citiesF4: [
          {
            area: "F0",
          },
          {
            area: "F1",
          },
          {
            area: "F2",
          },
          {
            area: "F3",
          },
          {
            area: "F4",
          },
          {
            area: "F5",
          },
          {
            area: "F6",
          },
          {
            area: "F7",
          },
          {
            area: "F8",
          },
          {
            area: "F9",
          },
          {
            area: "F10",
          },
          {
            area: "F11",
          },
          {
            area: "F12",
          },
          {
            area: "F13",
          },
          {
            area: "F14",
          },
          {
            area: "F15",
          },
          {
            area: "F16",
          },
          {
            area: "F17",
          },
          {
            area: "F18",
          },
          {
            area: "F19",
          },
          {
            area: "F20",
          },
          {
            area: "F21",
          },
          {
            area: "F22",
          }
        ],
        citiesM1: [
           {
              area: "FOOD TRAILER 4",
            },
            {
              area: "FOOD TRAILER 3",
            },
            {
              area: "FOOD TRAILER 2",
            },
            {
              area: "FOOD TRAILER 1",
            }
        ]
      },
      result: [],
      show: false,
      shopDetail: {},
      arr: [],
      couponAC: "",
      displacement: {
        scale: 1
      },
      btnShow: true
    };
  },
  methods: {
    // 地图缩放
    scale() {
      let that = this
      this.$nextTick(() => {
        let mapBox = document.querySelector(".mapSet")
          mapBox.addEventListener("touchstart", function(event) {
            var touches = event.touches;
            var events = touches[0];
            var events2 = touches[1];
            if(that.displacement.scale != 1) {
              event.preventDefault()
            }
            // 第一个触摸点的坐标
            that.displacement.pageX = events.pageX;
            that.displacement.pageY = events.pageY;
            that.displacement.moveable = true;
            if (events2) {
              that.displacement.pageX2 = events2.pageX;
              that.displacement.pageY2 = events2.pageY;
            }
    
            that.displacement.originScale = that.displacement.scale || 1;
          },{ passive: false })
          document.addEventListener("touchmove", function(event) {
            if (!that.displacement.moveable) {
              return;
            }
            event.preventDefault();
            var touches = event.touches;
            var events = touches[0];
            var events2 = touches[1];
            if(events2) {
              if (!that.displacement.pageX2) {
              that.displacement.pageX2 = events2.pageX;
              }
              if (!that.displacement.pageY2) {
                that.displacement.pageY2 = events2.pageY;
              }
              // 双指缩放比例计算
              var zoom = 
                that.getDistance(
                  {
                    x: events.pageX,
                    y: events.pageY
                  },
                  {
                    x: events2.pageX,
                    y: events2.pageY
                  }
                )/
                that.getDistance(
                  {
                    x: that.displacement.pageX,
                    y: that.displacement.pageY
                  },
                  {
                    x: that.displacement.pageX2,
                    y: that.displacement.pageY2
                  }
                )
              // 应用在元素上的缩放比例
              var newScale = that.displacement.originScale * zoom;
              // console.log(newScale)
              // // 最大缩放比例限制
              if (newScale < 1) {
                newScale = 1;
              }
              // 记住使用的缩放值
              that.displacement.scale = newScale
              mapBox.style.transform = "translate(" + newScale*20 + ","+ newScale*20 + ")";
              // mapBox.style.transform = "scale(" + newScale + ")"
            }
          })
      })
    },
    // 放大
    add() {
      this.$refs.map.style.transform = "scale(" + 1.875 + ")"
      this.$refs.maxB.style.overflow = "auto"
      this.btnShow = false
    },
    // 复原
    cut() {
      this.$refs.map.style.transform = "scale(" + 1 + ")" 
      this.$refs.maxB.style.overflow = "hidden"
      this.displacement.scale = 1
      this.btnShow = true
    },
    getdata() {
      coupon_detail({ b_id: this.$route.query.b_id }).then((res) => {
        if (res) {
          this.shopDetail = res.data;
          Object.keys(this.shopDetail.business_site).forEach((key) => {
            this.shopDetail.business_site[key].forEach((el) => {
              this.arr.push(el.area)
              Object.keys(this.cities).forEach((item,i)=> {
                this.cities[item].forEach((it,index) => {
                  if(it.area == el.area) {
                    it.id = el.id
                    let city = document.querySelector('.mapSet').children[i]
                    let isCity = city.children[0].children[index]
                    let cityStyle = getComputedStyle(city, null)
                    let isCityStyle = getComputedStyle(isCity, null)
                    let cityLeft,cityTop
                    let ind = 10
                    let cityL = cityStyle.left.substr(0, cityStyle.left.indexOf("p")) *1 + isCityStyle.width.substr(0, isCityStyle.width.indexOf("p")) * index
                    let cityT = cityStyle.top.substr(0, cityStyle.top.indexOf("p")) *1 + isCityStyle.height.substr(0, isCityStyle.height.indexOf("p")) * (index - 1)
                    this.$refs.spot.style.opacity = 1;
                    if(this.isIPhoneX() && this.isWeiXin()) {
                      if(i == 0 || i == 1) {
                        if(i == 0) {
                          cityLeft = cityStyle.left.substr(0, cityStyle.left.indexOf("p")) - 20
                        } else {
                          cityLeft = cityStyle.left.substr(0, cityStyle.left.indexOf("p")) + 20
                        }
                        if(index <= 2) {
                          cityTop = cityT
                        } else if(index >2 && index <= 6) {
                          cityTop = cityT + ind
                        } else {
                          cityTop = cityT + ind*2
                        }
                      } else if(i == 2 || i == 3) {
                        if(i == 2) {
                          cityLeft = cityStyle.left.substr(0, cityStyle.left.indexOf("p"))*1 - 20
                        } else {
                          cityLeft = cityStyle.left.substr(0, cityStyle.left.indexOf("p"))*1 + 10
                          console.log(cityLeft)
                        }
                        if(index <= 3) {
                          cityTop = cityT 
                        } else if(index >3 && index <= 6) {
                          cityTop = cityT + ind
                        } else if(index > 6 && index <= 9) {
                          cityTop = cityT + ind*2
                        } else if(index > 9 && index <= 13) {
                          cityTop = cityT + ind*3
                        } else if(index > 13 && index <= 17) {
                          cityTop = cityT + ind*7
                        } else{
                          cityTop = cityT + ind*8
                        }
                      } else if (i == 4) {
                        cityLeft = cityStyle.left.substr(0, cityStyle.left.indexOf("p"))*1 - 20
                        if(index <= 3 ) {
                          cityTop = cityT
                        } else if(index > 3 && index <= 6) {
                          cityTop = cityT + ind
                        } else if(index > 6 && index <= 9) {
                          cityTop = cityT + ind*2
                        } else if(index > 9 && index <= 12) {
                          cityTop = cityT + ind*3
                        } else if(index > 12 && index <= 15) {
                          cityTop = cityT + ind*4
                        } else if(index > 15 && index<= 18) {
                          cityTop = cityT + ind*5
                        } else if(index > 18 && index<= 21) {
                          cityTop = cityT + ind*6
                        } else {
                          cityLeft = cityStyle.left.substr(0, cityStyle.left.indexOf("p"))*1 + 20
                          cityTop = cityT + ind*7
                        }
                      } else if (i == 5) {
                        if(index == 0) {
                          cityLeft = cityL
                          cityTop = cityStyle.top.substr(0, cityStyle.top.indexOf("p"))*1 - 25
                        } else {
                          cityLeft = cityL - ind*9
                          cityTop = cityStyle.top.substr(0, cityStyle.top.indexOf("p"))*1 + ind*5
                        }
                      } else if(i == 7 || i == 8) {
                        if(i == 7) {
                          cityTop = cityStyle.top.substr(0, cityStyle.top.indexOf("p"))*1 - 30
                        } else {
                          cityTop = cityStyle.top.substr(0, cityStyle.top.indexOf("p"))*1
                        }
                        if(index <= 4) {
                          cityLeft = cityL
                        } else if(index > 4 && index <= 7) {
                          cityLeft = cityL + ind
                        } else if(index > 7 && index <= 11) {
                          cityLeft = cityL + ind*2
                        } else if(index > 11 && index <= 15) {
                          cityLeft = cityL + ind*7
                        } else if(index > 15 && index <= 18) {
                          cityLeft = cityL + ind*8
                        } else if(index > 18 && index <= 23) {
                          cityLeft = cityL + ind*9
                        } else {
                          cityLeft = cityL + ind*10
                        }
                      } else if(i == 9 || i == 10) {
                        if(i == 9) {
                          cityTop = cityStyle.top.substr(0, cityStyle.top.indexOf("p"))*1 - 30
                        } else {
                          cityTop = cityStyle.top.substr(0, cityStyle.top.indexOf("p"))*1
                        }
                        if(index <= 4) {
                          cityLeft = cityL
                        } else if(index > 4 && index <= 7) {
                          cityLeft = cityL + ind
                        } else if(index > 7 && index <= 10) {
                          cityLeft = cityL + ind*6
                        } else if(index > 11 && index <= 14) {
                          cityLeft = cityL + ind*7
                        } else if(index > 14 && index <= 19) {
                          cityLeft = cityL + ind*8
                        } else {
                          cityLeft = cityL + ind*9
                        }
                      } else if(i == 11) {
                        cityTop = cityStyle.top.substr(0, cityStyle.top.indexOf("p"))*1
                        if(index == 0) {
                          cityLeft = cityL
                        } else if(index == 1) {
                          cityLeft = cityL + ind*16
                        } else if(index == 2) {
                          cityLeft = cityL + ind*20
                        } else {
                          cityLeft = cityL + ind*23
                        }
                      } else {
                        cityLeft = cityStyle.left.substr(0, cityStyle.left.indexOf("p"))*1 + 10
                        cityTop = cityT
                      }
                    } else {
                      if(i == 0 || i == 1) {
                        if(i == 0) {
                          cityLeft = cityStyle.left.substr(0, cityStyle.left.indexOf("p")) - 20
                        } else {
                          cityLeft = cityStyle.left.substr(0, cityStyle.left.indexOf("p")) + 20
                        }
                        if(index <= 2) {
                          cityTop = cityT
                        } else if(index >2 && index <= 6) {
                          cityTop = cityT + ind
                        } else {
                          cityTop = cityT + ind*2
                        }
                      } else if(i == 2 || i == 3) {
                        if(i == 2) {
                          cityLeft = cityStyle.left.substr(0, cityStyle.left.indexOf("p"))*1 - 20
                        } else {
                          cityLeft = cityStyle.left.substr(0, cityStyle.left.indexOf("p"))*1 + 10
                          console.log(cityLeft)
                        }
                        if(index <= 3) {
                          cityTop = cityT 
                        } else if(index >3 && index <= 6) {
                          cityTop = cityT + ind
                        } else if(index > 6 && index <= 9) {
                          cityTop = cityT + ind*2
                        } else if(index > 9 && index <= 13) {
                          cityTop = cityT + ind*3
                        } else if(index > 13 && index <= 17) {
                          cityTop = cityT + ind*7
                        } else{
                          cityTop = cityT + ind*8
                        }
                      } else if (i == 4) {
                        cityLeft = cityStyle.left.substr(0, cityStyle.left.indexOf("p"))*1 - 20
                        if(index <= 3 ) {
                          cityTop = cityT
                        } else if(index > 3 && index <= 6) {
                          cityTop = cityT + ind
                        } else if(index > 6 && index <= 9) {
                          cityTop = cityT + ind*2
                        } else if(index > 9 && index <= 12) {
                          cityTop = cityT + ind*3
                        } else if(index > 12 && index <= 15) {
                          cityTop = cityT + ind*4
                        } else if(index > 15 && index<= 18) {
                          cityTop = cityT + ind*5
                        } else if(index > 18 && index<= 21) {
                          cityTop = cityT + ind*6
                        } else {
                          cityLeft = cityStyle.left.substr(0, cityStyle.left.indexOf("p"))*1 + 20
                          cityTop = cityT + ind*7
                        }
                      } else if (i == 5) {
                        if(index == 0) {
                          cityLeft = cityL
                          cityTop = cityStyle.top.substr(0, cityStyle.top.indexOf("p"))*1 - 25
                        } else {
                          cityLeft = cityL - ind*9
                          cityTop = cityStyle.top.substr(0, cityStyle.top.indexOf("p"))*1 + ind*5
                        }
                      } else if(i == 7 || i == 8) {
                        if(i == 7) {
                          cityTop = cityStyle.top.substr(0, cityStyle.top.indexOf("p"))*1 - 30
                        } else {
                          cityTop = cityStyle.top.substr(0, cityStyle.top.indexOf("p"))*1
                        }
                        if(index <= 4) {
                          cityLeft = cityL
                        } else if(index > 4 && index <= 7) {
                          cityLeft = cityL + ind
                        } else if(index > 7 && index <= 11) {
                          cityLeft = cityL + ind*2
                        } else if(index > 11 && index <= 15) {
                          cityLeft = cityL + ind*7
                        } else if(index > 15 && index <= 18) {
                          cityLeft = cityL + ind*8
                        } else if(index > 18 && index <= 23) {
                          cityLeft = cityL + ind*9
                        } else {
                          cityLeft = cityL + ind*10
                        }
                      } else if(i == 9 || i == 10) {
                        if(i == 9) {
                          cityTop = cityStyle.top.substr(0, cityStyle.top.indexOf("p"))*1 - 30
                        } else {
                          cityTop = cityStyle.top.substr(0, cityStyle.top.indexOf("p"))*1
                        }
                        if(index <= 4) {
                          cityLeft = cityL
                        } else if(index > 4 && index <= 7) {
                          cityLeft = cityL + ind
                        } else if(index > 7 && index <= 10) {
                          cityLeft = cityL + ind*6
                        } else if(index > 11 && index <= 14) {
                          cityLeft = cityL + ind*7
                        } else if(index > 14 && index <= 19) {
                          cityLeft = cityL + ind*8
                        } else {
                          cityLeft = cityL + ind*9
                        }
                      } else if(i == 11) {
                        cityTop = cityStyle.top.substr(0, cityStyle.top.indexOf("p"))*1
                        if(index == 0) {
                          cityLeft = cityL
                        } else if(index == 1) {
                          cityLeft = cityL + ind*16
                        } else if(index == 2) {
                          cityLeft = cityL + ind*20
                        } else {
                          cityLeft = cityL + ind*23
                        }
                      } else {
                        cityLeft = cityStyle.left.substr(0, cityStyle.left.indexOf("p"))*1 + 10
                        cityTop = cityT
                      }
                    }
                    this.$refs.spot.style.left = cityLeft + 'px'
                    this.$refs.spot.style.transform = "translate(" + 0 + "," + cityTop + "px)";
                    this.$refs.ripple.style.display = "block";
                  }
                })
              })
            })
          });
        } 
      });
    },
    useNow(id) {
     if (this.abc) {
       return false
     }
      if (this.$route.query.state == 0) {
        this.$dialog
        .confirm({
          message: "Are you sure you want to use this coupon now?",
          cancelButtonText: "Maybe later",
          confirmButtonText: "Yes",
        })
        .then(() => {
          this.abc = true
          use_coupon({ c_id: id }).then((res) => {
            if (res) {
              if (res.status == 2 || res.status == 1) {
                this.$router.push({
                  path: "/richmond/charge",
                  query: { b_id: this.$route.query.b_id },
                });
              } else {
                this.$notify({ type: "warning", message: res.msg });
              }
            }
          });
        });
      }else{
        this.$router.push({
          path: "/richmond/charge",
          query: { b_id: this.$route.query.b_id,state: 1 },
        });
      }
    },
    getDistance(start, stop) {
      // Math.hypot()计算参数的平方根
      return Math.hypot(stop.x - start.x, stop.y - start.y);
    },
    isIPhoneX() {
        var u = navigator.userAgent;
        var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if (isIOS) {    	
            if (screen.height == 812 && screen.width == 375){
                return true
            }else{
                return false
            } 
        }
    },
    isWeiXin() {
        var ua = window.navigator.userAgent.toLowerCase();
        if(ua.match(/MicroMessenger/i) == 'micromessenger'){
            return true;
        }else{
            return false;
        }
    },
    getCoupon() {
      let coupon = localStorage.getItem("coupon");
      if (coupon >= 1 && coupon <= 2) {
        console.log(111)
        let params = { 
          code: coupon == 1 ? 'night1' : (coupon == 2 ? 'night2' : '')
        }
        exchangeCoupon(params).then(res => {  
            if(res){
                console.log(res)
            }
        })
      }
      this.show = false;
    }
  },
  mounted() {
    if (localStorage.getItem("coupon")) {
      this.couponAC = localStorage.getItem("coupon");
    }
    // this.scale()
  },
  created() {
    this.getdata();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.path == "/richmond/charge") {
        const query=JSON.parse(JSON.stringify(vm.$route.query))
        query.state = 1;
        vm.$router.replace({path: vm.$route.path,query: query})
        if (localStorage.getItem("coupon") <= 2) {
          vm.show = false;
        }
      } else {
        vm.show = false;
      }
    });
  },
};
</script>

<style scoped lang="less">
.richmond {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  font-size: 16px;
  font-family: FuturaBT-BoldItalic;
  /deep/ .van-overlay {
    z-index: 99;
    .wrapper {
      width: 100%;
      height: 385px;
      position: relative;
      background: url("../../../assets/richmond/alert.png");
      background-size: 100% 100%;
      top: 50%;
      transform: translateY(-50%);
      .close {
        width: 24px;
        height: 24px;
        position: absolute;
        left: 299px;
        top: 55px;
        img {
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
        }
      }
      .couponbtn {
        width: 70%;
        position: relative;
        top: 146px;
        left: 55px;
      }
      .nicebtn {
        width: 60%;
        position: relative;
        top: 200px;
        left: 20%;
      }
    }
  }
  .bg {
    width: 100%;
    height: 470px;
    background: url("../../../assets/richmond/couponbg.png") no-repeat;
    background-size: 100%;
    position: absolute;
    top: 44px;
    left: 0;
    z-index: 2;
  }
  .main {
    width: 100%;
    background: url("../../../assets/richmond/point.png") #249FF2;
    background-size: 100%;
    position: relative;
    .main-head {
      position: relative;
      z-index: 8;
      padding: 54px 0 0;
      .advertise {
        display: flex;
        align-items: center;
        justify-content: center;
        div {
          width: 172px;
          height: 60px;
          text-align: center;
          line-height: 60px;
          border-radius: 10px;
          text-align: center;
          background: #fff;
          &:nth-child(2) {
            margin-left: 7px;
          }
        }
      }
      .pic-add-cut{
        z-index: 999;
        position: absolute;
        top: 64%;
        right: 7%;
        .pic{
          width: 30px;
          height: 30px;
          img{
            width: 100%;
            height: 100%;
          }
        }
      }
      .head-img {
        position: relative;
        top: 10px;
        display: flex;
        justify-content: center;
        width: 100%;
        .head_bg {
          z-index: 2;
          width: 80px;
          height: 80px;
          background: #5e00ba;
          border: 0.5px solid #000000;
          border-radius: 6px;
          img {
            width: 80px;
            height: 80px;
            position: relative;
            top: -4px;
            left: -4px;
            border-radius: 6px;
          }
        }
        .head_content {
          z-index: 1;
          padding: 10px;
          padding-left: 10px;
          width: 255px;
          height: 66px;
          position: relative;
          font-weight: 500;
          font-size: 18px;
          top: 14px;
          left: -5px;
          background-image: url("../../../assets/richmond/Vector.png");
          background-size: 100% 100%;
          font-weight: 600;
          p {
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            padding-left: 4px;
          }
          div {
            line-height: 28px;
            img {
              width: 24px;
              height: 24px;
              display: block;
            }
          }
        }
      }
      .head-map-box {
        position: relative;
        width: 375px;
        height: 405px;
        .max-b{
          width: 900px;
          height: 951px;
          border-radius: 15px;
          position: relative;
          left: -70%;
          top: -255px;
          transform: scale(0.375);
          transform-origin: center;
          overflow:hidden;
        }
        .mapSet {
          z-index: 999;
          border-radius: 15px;
          width: 900px;
          height: 951px;
          overflow: hidden;
          background: url("../../../assets/richmond/map2.png");
          background-size: 100% 100%;
          // position: absolute;
          // left: 0;
          // top: 0;
          // transform: scale(1) translate(0,0);
        }
        /deep/ .el-checkbox-group {
          display: inline-block;
          width: 19px;

          .el-checkbox-button {
            width: 19px;
            display: block;
            border-radius: 3px;
            background-color: rgb(13, 82, 124);

            .el-checkbox-button__inner {
              text-align: center;
              border-radius: 5px;
              color: #fff;
              background-color: transparent;
              border: none;
              outline: none;
              display: inline-block;
              width: 19px;
              height: 19px;
              font-size: 12px;
              line-height: 19px;
              margin: 0;
              padding: 0;
              transform: scale(0.75);
              box-shadow: none;
            }
          }

          .is-checked {
            background-color: #ff8e8e;
          }
        }

        
        .cOne {
          display: inline-block;
          position: absolute;
          left: 41.55%;
          top: 28.9%;
        }

        .cTwo {
          display: inline-block;
          position: absolute;
          left: 44.55%;
          top: 28.9%;
        }

        .bOne {
          display: inline-block;
          position: absolute;
          left: 54%;
          top: 23.76%;
        }

        .bTwo {
          display: inline-block;
          position: absolute;
          left: 57%;
          top: 23.76%;
        }

        .aOne {
          display: inline-block;
          position: absolute;
          left: 64.66%;
          top: 23.76%;
        }
        .fOne {
          display: inline-block;
          position: absolute;
          left: 40.44%;
          top: 60.43%;
        }

        .fTwo {
          display: inline-block;
          position: absolute;
          left: 44.2%;
          top: 66.2%;
        }

        .fThree {
          position: absolute;
          left: 18.25%;
          top: 78.25%;
        }

        .fFour {
          position: absolute;
          left: 18.25%;
          top: 81%;
        }

        .fFive {
          position: absolute;
          left: 32.27%;
          top: 86%;
        }
        .mOne {
          position: absolute;
          left: 32.27%;
          top: 88.75%;
        }
        .sOne{
          position: absolute;
          left: 45.8%;
          top: 92.2%;
        }
        .sOne {
          /deep/ .el-checkbox-group {
            width: auto;
            display: block;
            .el-checkbox-button {
              width: 40px;
              height: 22px;
              display: inline-block;
              position: relative;
              &::after {
                display: inline-block;
                content: "FOOD";
                position: absolute;
                top: -2px;
                width: 40px;
                height: 20px;
                font-size: 12px;
                left: 0;
                text-align: center;
                color: white;
                transform: scale(0.6);
                cursor: pointer;
              }
              .el-checkbox-button__inner {
                display: inline-block;
                width: 66px;
                height: 45px;
                line-height: 52px;
                transform: scale(0.6);
                position: relative;
                left: -13px;
                text-align: center;
                top: -10px;
              }
            }
          }
        }
        .spot{
          position: absolute;
          // left: 0;
          top: 0;
          width: 30px;
          height: 30px;
          opacity: 0;
          transform: translate(0,0);
          transition: transform 0.5s ease-in;
          .ripple {
            position: absolute;
            left: -5px;
            top: 6px;
            display: none;
            width: 40px;
            height: 40px;
            border: 3px solid red;
            border-radius: 50%;
            opacity: 1;
            transform: rotateX(65deg);
            animation: warn 1.5s linear infinite;
          }
          @keyframes warn {
              0% {
                  transform: scale(0) rotateX(70deg);
                  opacity: 0.7;
              }
              10% {
                 transform: scale(0.4) rotateX(70deg);
                  opacity: 0.63;
              }
              20% {
                  transform: scale(0.8) rotateX(70deg);
                  opacity: 0.56;
              }
              30% {
                  transform: scale(1.2) rotateX(70deg);
                  opacity: 0.49;
              }
              40% {
                  transform: scale(1.6) rotateX(70deg);
                  opacity: 0.42;
              }
              50% {
                  transform: scale(2.0) rotateX(70deg);
                  opacity: 0.35;
              }
              60% {
                  transform: scale(2.4) rotateX(70deg);
                  opacity: 0.28;
              }
               70% {
                  transform: scale(2.8) rotateX(70deg);
                  opacity: 0.21;
              }
               80% {
                  transform: scale(3.2) rotateX(70deg);
                  opacity: 0.14;
              }
               90% {
                  transform: scale(3.6) rotateX(70deg);
                  opacity: 0.07;
              }
              100% {
                  transform: scale(4) rotateX(70deg);
                  opacity: 0.0;
              }
          }
          img{
            width:  100%;
            animation: rotate .5s linear infinite;
            @keyframes rotate {
              from {
                transform: rotateY(0deg);
              }
              to {
                transform: rotateY(360deg);
              }
            }
          }
        }

        .cOne,
        .cTwo {
          /deep/ .el-checkbox-group {
            .el-checkbox-button {

              &:nth-child(4),
              &:nth-child(8) {
                margin-top: 10px;
              }
            }
          }
        }

        .fThree,
        .fFour {
          /deep/ .el-checkbox-group {
            .el-checkbox-button {
              &:nth-child(6),
              &:nth-child(9),
              &:nth-child(17),
              &:nth-child(20),
              &:nth-child(25) {
                margin-left: 10px;
              }

              &:nth-child(13) {
                margin-left: 50px;
              }
            }
          }
        }
        .fFive,.mOne {
          /deep/ .el-checkbox-group {
            .el-checkbox-button {
              &:nth-child(6),
              &:nth-child(12),
              &:nth-child(15),
              &:nth-child(20) {
                margin-left: 10px;
              }
              &:nth-child(9) {
                margin-left: 55px;
              }
            }
          }
        }
        .sOne {
          /deep/ .el-checkbox-group {
            .el-checkbox-button {
              &:nth-child(2) {
                margin-left: 160px;
              }
              &:nth-child(3),&:nth-child(4) {
                margin-left: 36px;
              }
            }
          }
        }
        .bOne,
        .bTwo,
        .aOne {
          /deep/ .el-checkbox-group {
            .el-checkbox-button {
              &:nth-child(5),
              &:nth-child(8),
              &:nth-child(11),
              &:nth-child(14),
              &:nth-child(17),
              &:nth-child(20) {
                margin-top: 10px;
              }
              &:nth-child(23) {
                left: 20px;
                top: -18.5px;
              }
            }
          }
        }
        .bOne,
        .bTwo {
          /deep/ .el-checkbox-group {
            .el-checkbox-button {

              // &:nth-child(5),
              // &:nth-child(8),
              // &:nth-child(11),
              &:nth-child(14),
              &:nth-child(17),
              &:nth-child(20) {
                margin-top: 0px;
              }
              &:nth-child(15) {
                margin-top: 40px;
              }
              &:nth-child(19) {
                margin-top: 10px;
              }
            }
          }
        }

        .fTwo {
          /deep/ .el-checkbox-group {
            width: 23px;
            .el-checkbox-button {
              width: 23px;
              .el-checkbox-button__inner {
                width: 23px;
                height: 21px;
                line-height: 21px;
                transform: scale(0.6);
                position: relative;
                left: -1px;
              }
            }
          }
        }
        .fOne {
          /deep/ .el-checkbox-group {
            width: 60px;
            position: relative;
            .el-checkbox-button {
              &:nth-child(1) {
                width: 60px;
                .el-checkbox-button__inner {
                  width: 60px;
                  height: 30px;
                  line-height: 30px;
                  transform: scale(0.6);
                  position: relative;
                  left: -1px;
                }
              }
              &:nth-child(2) {
                width: 38px;
                position: absolute;
                left: -55px;
                top: 80px;
                .el-checkbox-button__inner {
                  width: 50px;
                  height: 60px;
                  line-height: 70px;
                  transform: scale(0.6);
                  position: relative;
                  left: -5.5px;
                }
                &::after {
                  display: block;
                  position: absolute;
                  content: "";
                  width: 10px;
                  height: 22px;
                  border-radius: 0 0 0 2px;
                  background-color: rgb(138, 203, 221);
                  z-index: 9; 
                  top: -1px;
                  right: -1px;
                }
              }
            }
          }
        }
        .fThree,
        .fFour,
        .fFive,
        .mOne {
          display: block;
          /deep/ .el-checkbox-group {
            width: auto;
            display: block;
            .el-checkbox-button {
              width: 20px;
              display: inline-block;

              .el-checkbox-button__inner {
                width: 20px;
                height: 20px;
                line-height: 19px;
                transform: scale(0.6);
                position: relative;
              }
            }
          }
        }
        .active1 {
          background-color: #df3b3b !important;
        }
        .active2 {
          background-color: #fe7b00 !important;
        }
        .active3 {
          background-color: #65091b !important;
        }
        .active4 {
          background-color: #fbd432 !important;
        }
        .active5 {
          background-color: #4fa76a !important;
        }
        .active6 {
          background-color: #409eff !important;
        }
        .active7 {
          background-color: #c000fb !important;
        }
        .active8 {
          background-color: #00cfc5 !important;
        }
        .active9 {
          background-color: #fe006a !important;
        }
        .market {
          width: 30px;
          height: 30px;
          background-color: red;
          border-radius: 50%;
          position: absolute;
          top: 0;
          left: 50%;
          z-index: 1;
        }
      }
    }

    .coupon {
      position: relative;
      z-index: 8;
      width: 95%;
      margin: auto;
      margin-bottom: 50px;
      margin-top: 20px;
      padding: 20px 2.5% 20px;
      background-image: url("../../../assets/richmond/bg.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      li {
        padding: 0 0 0 10px;
        list-style: none;
        font-weight: 600;
        &::before {
          content: "";
          display: inline-block;
          margin: 0 10px 4px;
          width: 5px;
          height: 5px;
          background-color: #000;
          border-radius: 50%;
          font-size: 25px;
          font-weight: 600;
        }
      }
      p {
        padding: 0 35px;
        font-weight: 600;
      }
      .box {
        width: 94%;
        margin: 15px auto;
        overflow: hidden;
        .item {
          width: 97%;
          height: 154px;
          padding-top: 24px;
          box-sizing: border-box;
          margin: 10px 5px;
          background-image: url("../../../assets/richmond/backgroundCoupon.png");
          background-size: 100% 100%;
          .item-pic {
            width: 270px;
            height: 107px;
            margin: auto;
            position: relative;
            img {
              margin: auto;
              width: 270px;
              height: 103px;
              position: relative;
              left: -5px;
            }
            .item-pic-grey {
              -webkit-filter: grayscale(100%);
              -moz-filter: grayscale(100%);
              -ms-filter: grayscale(100%);
              -o-filter: grayscale(100%);
              filter: grayscale(100%);
              filter: gray;
            }
            div {
              position: absolute;
              top: 0;
              left: 0;
              width: 270px;
              height: 107px;
              text-align: center;
              line-height: 100px;
              font-size: 35px;
              color: red;
              font-weight: 600;
            }
          }
          .item-content {
            width: 90%;
            margin: 12px auto 0;
            display: flex;
            justify-content: space-between;
            .item-market {
              display: flex;
              div {
                line-height: 33px;
                font-weight: 600;
                margin-right: 5px;
                img {
                  width: 24px;
                  margin-top: 5px;
                }
              }
            }
            .use-btn {
              padding: 0 31px;
              height: 33px;
              box-sizing: border-box;
              background: #6300c4;
              border-radius: 18px;
              color: #fff;
              line-height: 33px;
              font-size: 16px;
            }
          }
          &::before {
            display: none;
          }
        }
      }
    }
    .rules {
      padding: 10px 10px 40px 10px;
      .rule_title {
        font-weight: 600;
        position: relative;
        .rule_titlebg {
          position: absolute;
          top: 18px;
          left: -2px;
          width: 100px;
          height: 5px;
          background: #6200c3cb;
          border-radius: 30px;
        }
      }
      li {
        margin-top: 15px;
        font-weight: normal;
        line-height: 24px;
        &::before {
          content: "";
          display: inline-block;
          margin: 0 6px 4px 8px;
          width: 4px;
          height: 4px;
          background-color: #000;
          border-radius: 50%;
          font-size: 25px;
          font-weight: 600;
        }
      }
      .rule_content {
        line-height: 24px;
        padding-left: 30px;
      }
    }
  }
  .flex-btn {
    z-index: 10;
    bottom: 0;
    height: 60px;
    background-color: #030424ee;
    position: fixed;
    padding: 10px 10px;
    width: 100%;
    border-radius: 25px 25px 0 0;
    .flex-item {
      width: 70%;
      margin: auto;
      height: 40px;
      background: #089bd6;
      border: 1px solid rgba(51, 165, 255, 0);
      border-radius: 30px;
      div {
        position: relative;
        top: -2px;
        width: 100%;
        height: 38px;
        text-align: center;
        color: #fff;
        line-height: 38px;
        background: linear-gradient(
          180deg, rgba(51, 165, 255, 0) 20.83%, 
          rgba(77, 176, 255, 0.9) 100%), 
          #008EFF;
        border-radius: 30px;
      }
    }
    .use-btn2 {
      background: #979797 !important;
    }
  }
}
</style>