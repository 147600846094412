/** 
 *   优惠券
 */
import { get } from '@/untils/js/axios.js'

// 获取优惠券
export const getCouponList = params => get('/coupon_list', params)

// 获取优惠券
export const exchangeCoupon = params => get('/get_coupon', params)
